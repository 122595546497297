a {
    text-decoration: none;
    color: inherit;
}

* {
    color: inherit;
}

#nprogress .bar {
    background: #00b54e !important;
}

html {
    height: 100%;
    width: 100vw;
}

body {
    overflow-x: hidden;
    padding-right: 0 !important;
}
